/**
 * Use this class to create a reactive object containing all of your URL query parameters.
 * Using this class getters and setters, you will automatically sync your items with the URL.
 */

import { Router, RouteLocationNormalizedLoaded, LocationQueryValue } from 'vue-router';

export interface QueryParameter {
    key: string;
    value?: string | LocationQueryValue[] | null;
    defaultValue?: string | null;
    localStorageKey?: string;
    transform?: Function;
}

export interface UrlQuery {
    [key: string]: string | LocationQueryValue[] | null;
}

export class QueryParameters {
    router: Router;
    route: RouteLocationNormalizedLoaded;
    items: Array<QueryParameter>;
    onUpdateCallback?: Function;
    postUpdateCallback?: Function;

    constructor(router: Router, route: RouteLocationNormalizedLoaded, items: Array<QueryParameter>, onUpdateCallback?: Function, postUpdateCallback?: Function) {
        this.router = router;
        this.route = route;
        this.items = items;
        this.onUpdateCallback = onUpdateCallback;
        this.postUpdateCallback = postUpdateCallback;

        this.setupQueryParameters();
        this.updateRoute(false);
    }

    setupQueryParameters() {
        this.items.map((item) => {
            item.value = this.getInitialItemValue(item);
        });
    }

    getInitialItemValue(item: QueryParameter): any {
        if (this.route.query[item.key]) {
            return this.route.query[item.key];
        }

        if (item.localStorageKey && localStorage.getItem(item.localStorageKey) && localStorage.getItem(item.localStorageKey) !== 'undefined') {
            return localStorage.getItem(item.localStorageKey);
        }

        return item.defaultValue || null;
    }

    getItem(key: string): QueryParameter {
        const item = this.items.find((item) => item.key === key);
        if (!item) {
            throw new Error(`Query parameter with key '${key}' not found.`);
        }
        return item;
    }

    all(): Array<QueryParameter> {
        return this.items;
    }

    hasItem(key: string): boolean {
        return this.items.some((item) => item.key === key);
    }

    getRaw(key: string): string {
        return this.get(key, false);
    }

    get(key: string, transform: boolean = true): any {
        return this.transformItem(this.getItem(key), transform);
    }

    getAsArray(key: string, separator: string = ','): Array<any> {
        const item = this.getItem(key);
        return item.value ? item.value.split(separator) : [];
    }

    transformItem(item: QueryParameter, transform: boolean = true) {
        return transform && item.transform ? item.transform(item.value) : item.value;
    }

    setSilently(key: string, value: string) {
        this.set(key, value, false, false);
    }

    toggleInArray(key: string, value: string, updateRoute: boolean = true, runUpdateCallback: boolean = true) {
        const item = this.getItem(key);
        const array = item.value ? item.value.split(',') : [];
        const index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }
        this.set(key, array.join(','), updateRoute, runUpdateCallback);
    }

    set(key: string, value: string | null, updateRoute: boolean = true, runUpdateCallback: boolean = true) {
        const item = this.getItem(key);

        if (item.value === value) {
            return;
        }

        item.value = value;

        if (updateRoute) {
            this.updateRoute(runUpdateCallback);
        }

        if (item.localStorageKey && value && value !== 'undefined') {
            localStorage.setItem(item.localStorageKey, value);
        }
    }

    setMultiple(values: Array<{ key: string; value: string | null }>, runUpdateCallback: boolean = true) {
        values.forEach((item) => {
            this.set(item.key, item.value, false);
        });

        this.updateRoute(runUpdateCallback);
    }

    updateRoute(runUpdateCallback: boolean = true) {
        const query: UrlQuery = {};

        this.items.forEach((item) => {
            if (item.value) {
                query[item.key] = item.value;
            }
        });

        this.router.push({
            query,
        });

        if (this.onUpdateCallback && runUpdateCallback) {
            this.onUpdateCallback(this.items);
            this.postUpdateCallback?.(this.items);
        }
    }

    isInArray(key: string, value: string): boolean {
        const array = this.getAsArray(key);
        return array.includes(value);
    }
}

function reactiveQueryParameters(router: Router, route: RouteLocationNormalizedLoaded, items: Array<QueryParameter>, onUpdateCallback?: Function) {
    return reactive(new QueryParameters(router, route, items, onUpdateCallback));
}

export { reactiveQueryParameters };
